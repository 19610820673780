import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import DefaultLayoutMobile from '@/layouts/DefaultLayoutMobile'
import PrintLayout from '@/layouts/PrintLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/pages/login',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/change-password',
        name: 'Change Password',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/ChangePassword.vue'
          ),
      },
      // Orders
      {
        path: '/order-processing/all-orders',
        name: 'Orders',
        component: () =>
          import('@/views/warehouse/order_processing/Orders.vue'),
      },
      // Manage
      {
        path: '/manage/getting-started',
        name: 'Getting started',
        component: () => import('@/views/warehouse/manage/GettingStarted.vue'),
      },
      {
        path: '/manage/barcodes',
        name: 'Barcodes',
        component: () => import('@/views/warehouse/manage/Barcodes.vue'),
      },
      {
        path: '/manage/warehouse',
        name: 'Manage Warehouse',
        component: () => import('@/views/warehouse/manage/Warehouse.vue'),
      },
      {
        path: '/manage/zone',
        name: 'Manage Zone',
        component: () => import('@/views/warehouse/manage/Zone.vue'),
      },
      {
        path: '/manage/aisle-rack-shelf',
        name: 'Manage Aisle, Rack and Shelf locations',
        component: () =>
          import('@/views/warehouse/manage/AisleRackShelfLocation.vue'),
      },
      {
        path: '/manage/trolley-bin',
        name: 'Manage Trolley & Bin',
        component: () => import('@/views/warehouse/manage/TrolleyBin.vue'),
      },
      {
        path: '/manage/users',
        name: 'Manage Users',
        component: () => import('@/views/warehouse/manage/Users.vue'),
      },
      {
        path: '/manage/billing/',
        name: 'Manage Billing',
        component: () => import('@/views/warehouse/manage/Billing.vue'),
      },
      {
        path: '/manage/billing/:id',
        name: 'Billing List',
        component: () => import('@/views/warehouse/manage/BillingDetail.vue'),
      },
      {
        path: '/manage/quotations',
        name: 'Manage Quotations',
        component: () => import('@/views/warehouse/manage/Quotation.vue'),
      },
      {
        path:"/manage/error-logs",
        name:"Error Logs",
        component: () => import('@/views/warehouse/manage/ShopifyErrors.vue'),
     },
      
      {
        path: '/manage/custom-invoices',
        name: 'Manage Custom Invoices',
        component: () => import('@/views/warehouse/manage/CustomInvoices.vue'),
      },
      {
        path: '/manage/vendors',
        name: 'Manage Vendors',
        component: () => import('@/views/adminportal/manage/Vendors.vue'),
      },
      {
        path: '/manage/reports',
        name: 'Reports',
        component: () => import('@/views/warehouse/manage/reports.vue'),
      },
      {
        path: '/manage/vendors/:id',
        name: 'Vendor Details',
        component: () => import('@/views/adminportal/manage/VendorDetails.vue'),
      },
      {
        path: '/manage/contracts',
        name: 'Contracts',
        component: () => import('@/views/warehouse/manage/Contracts.vue'),
      }

      /////////////////////////////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'forgot-password',
        name: 'Forgot Password',
        component: () => import('@/views/pages/forgotPassword'),
      },
      {
        path: 'reset-password/:id',
        name: 'Reset Password',
        component: () => import('@/views/pages/resetPassword'),
      },
      // {
      //   path: 'print-barcode',
      //   name: 'Print Barcode',
      //   component: () => import('@/views/warehouse/manage/PrintBarcodes.vue'),
      // },
    ],
  },
  {
    path: '/print',
    name: 'Print area',
    component: PrintLayout,
    redirect: '/dashboard',
    children: [
      {
        path: 'invoice',
        name: 'Print invoice',
        component: () => import('@/views/warehouse/print/Invoice.vue'),
      },
      {
        path: 'shipping-label',
        name: 'Shipping label',
        component: () => import('@/views/warehouse/print/ShippingLabel.vue'),
      },
      {
        path: 'sku-barcode',
        name: 'Print SKU barcode',
        component: () => import('@/views/warehouse/print/SKUs.vue'),
      },
      {
        path: 'artefact-barcode',
        name: 'Print barcode',
        component: () => import('@/views/warehouse/print/Barcodes.vue'),
      },
      {
        path: 'order-view',
        name: 'View Order',
        component: () => import('@/views/warehouse/print/OrderView.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
